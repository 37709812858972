import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { checkFileType } from '../../../../utils/ContractUtils';
import { handlePreviewIcon } from '../../../../utils/FileUpload';
import { setErrMsg, setSuccessMsg } from '../../../../redux/actions/snackbarActions';

import withStyles from '@mui/styles/withStyles';
import FastTrackStyles from '../../../../styles/FastTrackStyles';

function FileInputFormik({ name, title, disabled, formik, classes }) {
  const dispatch = useDispatch();
  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));
  const files = formik.values[name];

  function setFiles(fileObjs) {
    formik.setFieldValue(name, fileObjs);
  }

  async function handleFileDrop(fileObjs) {
    const fileObj = fileObjs[0];
    if (fileObj) {
      const { validMimetype } = await checkFileType(fileObj.file);
      if (!validMimetype) {
        setFiles([]);
        setErr(
          `Invalid file type for ${fileObj.file.name}. Must be a valid doc, docx, or pdf.`
        );
        return;
      }
    }
    if (disabled) {
      setFiles([]);
      setErr(`Unable to add file. File input is currently disabled for this form.`);
      return;
    }

    setSuccess(`File ${fileObj?.file.name} successfully added.`);
    setFiles(fileObjs);
  }

  function handleFileRemove(file) {
    setFiles(files.filter((f) => f !== file));
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item className={classes.zeroPaddingBottom}>
        <Grid item>
          <Typography className={classes.floatLeft}>{title}</Typography>
        </Grid>
        <Grid item className={classes.zeroPaddingBottom}>
          <Grid container spacing={2}>
            <Grid item className={classes.baseFullWidth}>
              <DropzoneAreaBase
                classes={{ root: classes.formikFileInput }}
                fileObjects={files}
                onAdd={(files) => handleFileDrop(files)}
                onDelete={(files) => handleFileRemove(files)}
                getPreviewIcon={handlePreviewIcon}
                showFileNames
                clearOnUnmount
                maxFileSize={300000000}
                filesLimit={10}
                showAlerts={false}
                acceptedFiles={['.doc', '.docx', '.pdf']}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(FastTrackStyles)(FileInputFormik);
