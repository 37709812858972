import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, Typography, IconButton } from '@mui/material';

import { SECTION_STATUS, SECTION_STATUS_TO_DISPLAY_TEXT } from './constants';
import AnswerQuestionsStatusStyles from './styles';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { MAX_RULE_STRENGTH } from '../../config';
import {
  ResetAnswerQuestions,
  SetCurrentSection,
  SetSectionStatus,
  DeleteSectionStatus,
  SetCurrentQuestion,
} from '../../redux/actions/answerQuestionsActions';
import { setErrMsg, setSuccessMsg } from '../../redux/actions/snackbarActions';
import { SaveAnswerQuestionsRules } from '../../utils/requests';
import { PlaybookBuilderPage } from '../PlaybookBuilderPage';

function AnswerQuestionsStatus({
  classes,
  modelId,
  handleNext,
  handleBack,
  redirectTo,
  onboarding,
}) {
  const {
    getAuthHeader,
    config: { ruleStrengthDivisor },
  } = useAuthUpdateContext();

  const dispatch = useDispatch();
  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));

  const { statusLookup } = useSelector((state) => state.AnswerQuestions);

  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    setDisableSave(false);
    if (!statusLookup || Object.keys(statusLookup).length === 0) {
      handleBack();
    }
  }, [statusLookup]);

  function backLink() {
    handleBack();
  }

  function isSectionIncomplete() {
    return Object.entries(statusLookup || {}).some(([_, section]) => {
      return [SECTION_STATUS.IN_PROGRESS, SECTION_STATUS.INCOMPLETE].includes(
        section.status
      );
    });
  }

  function handleRemove(section) {
    dispatch(DeleteSectionStatus(section._id));
  }

  function startOrEditLink(section) {
    dispatch(SetCurrentSection(section));
    dispatch(SetCurrentQuestion(section.questions[0]));
    dispatch(SetSectionStatus(section._id, SECTION_STATUS.IN_PROGRESS));
    if (onboarding) {
      handleNext();
    } else {
      handleNext(`../${section.className}`);
    }
  }

  function saveLink() {
    setDisableSave(true);
    const payload = {
      data: Object.entries(statusLookup)
        .map(([_, section]) => section)
        .filter(({ status }) => status === SECTION_STATUS.COMPLETE),
      modelId,
      ruleStrengthDivisor,
      ruleStrengthMax: MAX_RULE_STRENGTH,
      unselectedSectionIds: Object.entries(statusLookup)
        .filter(([_, { status }]) => status === SECTION_STATUS.UNSELECTED)
        .map(([sectionId, _]) => sectionId),
    };
    getAuthHeader().then((headers) => {
      SaveAnswerQuestionsRules(headers, payload)
        .then((SUCCESS) => {
          if (SUCCESS) {
            // redirect to the rules library page for the model
            dispatch(ResetAnswerQuestions());
            if (!onboarding) {
              setSuccess('Rules created');
            }
            redirectTo();
            return;
          }
        })
        .catch((err) => {
          console.error(err);
          setErr('Failed to create rules');
        });
    });
  }

  return (
    <PlaybookBuilderPage
      title="NDA Questionnaire"
      extendedTitle=""
      description={
        'By answering questions within these sections you will create rules that define' +
        ' how your contracts are edited in the future. You can edit how these rules are built here' +
        ' as well as by custom editing the rules from the model overview screen.'
      }
      lButtonOnClick={backLink}
      rButtonOnClick={saveLink}
      rButtonDisabled={isSectionIncomplete() || disableSave}
      rButtonText="Save"
    >
      <Grid container direction="column">
        {Object.entries(statusLookup || {}).map(([sectionId, sectionData], idx) => (
          <React.Fragment key={sectionId}>
            <div className={classes.dividerTop} />
            <Grid item key={sectionId} container alignItems="center">
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h3">
                    <span>{sectionData.name}</span>
                    {sectionData.status !== SECTION_STATUS.UNSELECTED ? (
                      <span className={classes.ruleCount}>
                        {sectionData.questions.length === 1
                          ? `(1 Question)`
                          : `(${sectionData.questions.length} Questions)`}
                      </span>
                    ) : (
                      <span className={classes.ruleCount}>(Default)</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Typography>
                        {SECTION_STATUS_TO_DISPLAY_TEXT[sectionData.status]}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {sectionData.status === SECTION_STATUS.INCOMPLETE ? (
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.startEditButton}
                          onClick={() => startOrEditLink(sectionData)}
                        >
                          Start
                        </Button>
                      ) : (
                        (sectionData.status === SECTION_STATUS.IN_PROGRESS ||
                          sectionData.status === SECTION_STATUS.COMPLETE) && (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.startEditButton}
                            onClick={() => startOrEditLink(sectionData)}
                          >
                            Edit
                          </Button>
                        )
                      )}
                    </Grid>
                    <Grid item>
                      {sectionData.status !== SECTION_STATUS.UNSELECTED && (
                        <IconButton
                          aria-label="delete section"
                          onClick={() => handleRemove(sectionData)}
                          size="large"
                        >
                          <DeleteIcon fontSize="small" className={classes.deleteButton} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {Object.keys(statusLookup).length === idx + 1 && (
              <div className={classes.dividerBottom} />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </PlaybookBuilderPage>
  );
}

AnswerQuestionsStatus.defaultProps = {
  onboarding: false,
};

AnswerQuestionsStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired,
  modelId: PropTypes.string.isRequired,
  onboarding: PropTypes.bool,
};

export default withStyles(AnswerQuestionsStatusStyles)(AnswerQuestionsStatus);
