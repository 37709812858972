import Colors from '../../styles/Colors';

const BucketedCheckboxDisplayStyles = () => ({
  selectAllCheckbox: {
    color: Colors.blue,
    textDecoration: 'underline',
  },
  checkboxButtonWrapper: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionText: {
    color: 'black',
    fontSize: 'small',
    textTransform: 'none',
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
  },
  checkboxGridItemWrapper: {
    width: '100%',
  },
  divider: {
    borderBottom: `1px solid ${Colors.grey1}`,
    marginTop: 10,
    marginBottom: 10,
  },
  selectAll: {
    width: '100%',
    color: 'black',
    fontSize: 'small',
    textTransform: 'none',
    borderBottom: `1px solid ${Colors.grey1}`,
    float: 'left',
    justifyContent: 'left',
    marginTop: 10,
  },
});

export default BucketedCheckboxDisplayStyles;
