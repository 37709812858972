import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';

import BlackBoilerLogo from '../../assets/images/bb_logo_oneline.png';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { CreateModelFromInputs } from '../../redux/actions/modelActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { GetQuestionnairesByContractType } from '../../requests/questionnaire';
import { capitalizeFirstLetter } from '../../utils/formatting';
import { getSubdomain } from '../../utils/OnboardingUtils';
import OnboardingStepContent from './OnboardingStepContent';
import OnboardingDialogStyles from './OnboardingDialogStyles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function getSteps() {
  return [
    'Create Model',
    'Select Questions',
    'Answer Question Status',
    'Answer Questions',
  ];
}

const OnboardingDialog = ({ classes, open, handleClose, createdModelId }) => {
  const { getAuthHeader, setConfig, config } = useAuthUpdateContext();

  const {
    setError,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    resetField,
  } = useForm({
    defaultValues: {
      name: '',
      contractType: '',
      defaultQuestionnaire: '',
    },
  });

  const [defaultQuestionnaires, setDefaultQuestionnaires] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [modelId, setModelId] = useState(createdModelId);
  const [isChangedPreferredModel, setIsChangedPreferredModel] = useState(false);
  const [contractTypes, setContractTypes] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const steps = getSteps();
  const dispatch = useDispatch();
  const subdomain = getSubdomain();

  const setErr = (value) => dispatch(setErrMsg(value));

  const getQuestionnairesByContractType = (type) => {
    getAuthHeader().then((headers) => {
      GetQuestionnairesByContractType(headers, type)
        .then((data) => {
          // If there is one default questionare, set setValue
          if (data.length === 1) {
            setValue('defaultQuestionnaire', data[0]._id);
          }
          setDefaultQuestionnaires(data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  useEffect(() => {
    setModelId(createdModelId);
    setShowResult(false);
  }, [createdModelId]);

  useEffect(() => {
    if (watch().contractType) {
      // when every change of contract type
      // 1- clean contract type error
      clearErrors(['contractType']);
      // 2- reset default questionnaire field for reset again
      resetField('defaultQuestionnaire');
      getQuestionnairesByContractType(watch().contractType);
    }
  }, [watch().contractType]);

  useEffect(() => {
    if (watch().name.length) {
      clearErrors(['name']);
    }
  }, [watch().name]);

  useEffect(() => {
    // set company name
    const name = config?.displayName || config?.name;
    if (name) setValue('name', capitalizeFirstLetter(name));

    if (modelId) setActiveStep(1);
    else {
      // set playbook name with company name
      if (name) setValue('name', capitalizeFirstLetter(name));

      if (config && config.contractTypes) {
        setContractTypes(config.contractTypes);
        if (config.contractTypes.length === 1) {
          getQuestionnairesByContractType(config.contractTypes[0].name);
          setValue('contractType', config.contractTypes[0].name);
        }
      }
    }
  }, []);

  const handleSetActiveStep = (currStep) => {
    setActiveStep(currStep);
  };

  const handleShowResult = () => {
    setShowResult(true);
  };

  const handleCreate = (data) => {
    if (!getValues('name').length && activeStep === 0) {
      setError('name', { message: 'Playbook name cannot be blank' });
      return;
    }
    if (!getValues('contractType') && activeStep === 0) {
      setError('contractType', { message: 'Must be selected contract type' });
      return;
    }
    if (!defaultQuestionnaires.length && activeStep === 0) {
      setError('contractType', {
        message:
          'Contract type does not have questionnaire, please select a different contract type',
      });
      return;
    }
    if (!getValues('defaultQuestionnaire') && activeStep === 0) {
      setError('defaultQuestionnaire', {
        message: 'Must be selected questionnaire',
      });
      return;
    }

    getAuthHeader().then((headers) => {
      const { name, contractType, defaultQuestionnaire } = data;
      const modelData = {
        name,
        isDeleteProtected: false,
        stage: 'published',
        contractType,
        defaultQuestionnaire,
        groups: [],
        generateAddendum: false,
        addendumTemplate: null,
        emailOnUpload: false,
        convertPdfToDocx: false,
        onboarding: true,
      };
      Promise.resolve(dispatch(CreateModelFromInputs(headers, modelData)))
        .then(
          ({ modelId: newModelId, isChangedPreferredModel: preferredModelChanged }) => {
            setModelId(newModelId);
            setIsChangedPreferredModel(preferredModelChanged);
            setConfig({ ...config, showOnboarding: false });
            handleSetActiveStep(1);
          }
        )
        .catch((err) => {
          console.log('Error! Failed to create q&a model with error:', err);
          setErr('Error! Failed to create q&a model');
        });
    });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={showResult ? 'xs' : 'lg'}
        fullWidth
      >
        {showResult ? (
          <Grid
            container
            className={classes.gridContainer}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <CheckCircleIcon className={classes.image} />
            </Grid>
            <Grid item>
              <Typography variant="h1">Thank you!</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Playbook Created. Ready to use.
                {isChangedPreferredModel &&
                  ` Send documents to review@${subdomain}.blackboiler.com address to be processed.`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.popupButton}
                variant="contained"
                onClick={() => handleClose()}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <img
                    src={BlackBoilerLogo}
                    className={classes.logo}
                    alt="BlackBoiler Company Logo"
                  />
                </Grid>
                <Grid item>
                  {activeStep === 0 && <Typography variant="h1">WELCOME</Typography>}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel className={classes.stepLabel}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  <div>
                    <Typography className={classes.instructions}>
                      <OnboardingStepContent
                        step={activeStep}
                        getValues={getValues}
                        setValue={setValue}
                        errors={errors}
                        defaultQuestionnaires={defaultQuestionnaires}
                        modelId={modelId}
                        contractTypes={contractTypes}
                        handleSetActiveStep={handleSetActiveStep}
                        handleShowResult={handleShowResult}
                      />
                    </Typography>
                    {activeStep === 0 && (
                      <div className={classes.buttonGroups}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit(handleCreate)}
                        >
                          Create
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

OnboardingDialog.defaultProps = {
  createdModelId: null,
};

OnboardingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  createdModelId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(OnboardingDialogStyles)(OnboardingDialog);
