import React, { useEffect, useState } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import { TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY } from '../../config';
import YesNoStyles from '../../styles/YesNoStyles';
import { TextEditor } from '../TextEditor';

function YesNoTextEditor({ classes }) {
  const dispatch = useDispatch();
  const modelData = useSelector((state) => state.Model.currentModelData);
  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );
  const [templated, setTemplated] = useState('');
  const [raw, setRaw] = useState('');

  useEffect(() => {
    dispatch(
      SetAnswer(currentSection._id, currentQuestion._id, {
        showEditor: !!currentQuestion.answer?.showEditor,
        selection: currentQuestion.answer?.selection || 'NO',
        raw,
        templated,
      })
    );
  }, [raw]);

  return (
    <Grid container direction="column">
      <Grid item xs>
        <RadioGroup
          row
          aria-label="yesNo"
          name="yesNo"
          value={currentQuestion.answer}
          onChange={(evt) =>
            dispatch(SetAnswer(currentSection._id, currentQuestion._id, evt.target.value))
          }
          className={classes.radioGroup}
        >
          <FormControlLabel
            value="YES"
            control={<Radio className={classes.radioButton} />}
            label="Yes"
            className={classes.formControlLabel}
          />
          <FormControlLabel
            value="NO"
            control={<Radio className={classes.radioButton} />}
            label="No"
            className={classes.formControlLabel}
          />
        </RadioGroup>
      </Grid>
      {currentQuestion.answer && currentQuestion.answer.showEditor ? (
        <Grid item>
          <div style={{ width: '75%' }}>
            <TextEditor
              setTemplated={setTemplated}
              setRaw={setRaw}
              slotStrategy={TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY.TRAINING}
              initialValue={value.templated ? value.templated : ''}
              contractType={modelData.contractType}
            />
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
}

YesNoTextEditor.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(YesNoStyles)(YesNoTextEditor);
