export default {
  acceptEdit: '#f1f8e9',
  rejectEdit: '#ffebee',
  reviseEdit: '#fff4e4',
  brightRed: '#ff0000',
  blue: '#0D6DFF',
  blueRGB: '13,109,255',
  blue2: '#1362D9',
  blue3: '#A2C7FF',
  blue3RGB: '162, 199, 255',
  blue4: '#1EAB9E',
  blue5: '#205782',
  lightBlue: '#5bc1de',
  lightBlue2: '#EEF7FD',
  lightBlue3: '#abdbe3',
  pageContentBlue: '#f7fafd',
  grey1: '#E7F1FB',
  grey2: '#5C6E81',
  grey2RGB: '92,110,129',
  grey3: '#8E9499',
  grey3RGB: '142, 148, 153',
  grey4: '#222222',
  grey5: '#767676',
  grey6: '#F5F6FA',
  grey7: '#f7f9fa',
  grey8: '#E0E3E7',
  grey9: '#5A5A5A',
  grey10: '#b9b9b9',
  darkGrey: '#5c6e81',
  lightGrey: '#f5f6fa',
  lightGrey2: '#0000000a',
  goldGrey: '#dbd7d2',
  yellowRGB: '255,149,0',
  greenRGB: '19, 135, 96',
  pageBackground: '#FEFFFE',
  red: '#A00C10',
  red2: '#f4e6e6',
  white: '#FAF7F9',
  trueWhite: '#FFFFFF',
  green: '#138760',
  green2: '#caeaca',
  green3: '#6FD64A',
  green4: '#67AE5C',
  black: '#000000',
  textHighlight: '#d0e1fe',
  textDiffGreen: '#319831',
  textDiffRed: '#fc2222',
  editTypeRevise: '#FFE766',
  editTypeAccept: '#bbe6e2',
  editTypeReject: '#f190af',
  trainTypeGeneric: '#8fbf9b',
  trainTypeGenericSelected: '#8fbf9b40',
  trainTypeUnchanged: '#dfe8fe',
  trainTypePoint: '#fee7af',
  trainTypeFSI: '#e1eddb',
  trainTypeFPI: '#e1dfee',
  trainTypeFSD: '#fcd3bb',
  trainTypeFPD: '#f0d8b2',
  trainTypeLeafList: '#fff1e0',
  slotsReceivingParty: {
    background: '#1a6f87',
    opacity: 0.3,
  },
  slotsDisclosingParty: {
    background: '#872042',
    opacity: 0.3,
  },
  slotsConfidentialInformation: {
    background: '#138760',
    opacity: 0.3,
  },
  slotsAgreement: {
    background: '#874505',
    opacity: 0.3,
  },
  slotsDeal: {
    background: '#1a6f87',
    opacity: 0.1,
  },
  slotsServiceProvider: {
    background: '#0c8719',
    opacity: 0.3,
  },
  slotsServiceReceiver: {
    background: '#ff0000',
    opacity: 0.5,
  },
  slotsServices: {
    background: '#ff0000',
    opacity: 1,
  },
  slotsSubcontractor: {
    background: '#ff0000',
    opacity: 1,
  },
  slotsContractor: {
    background: '#ff0000',
    opacity: 1,
  },
  slotsGeneralProject: {
    background: '#ff0000',
    opacity: 1,
  },
  slotsSubcontractWork: {
    background: '#ff0000',
    opacity: 1,
  },
  slotsOwner: {
    background: '#ff0000',
    opacity: 1,
  },
  // Used for charts, this random list of colors was generated by npm randomColor
  colorPalette: [
    '#e57839',
    '#39a814',
    '#4ca8b2',
    '#22b4d8',
    '#ea5282',
    '#d14090',
    '#c6e021',
    '#83dd4f',
    '#412296',
    '#660d96',
    '#303ce8',
    '#bbf762',
    '#4fd677',
    '#2823aa',
    '#3bd12e',
    '#44c982',
    '#ef5876',
    '#ed49c9',
    '#ecef13',
    '#c251e8',
    '#2a8199',
    '#d84e67',
    '#3596ff',
    '#54f2a6',
    '#dd832e',
    '#d1583a',
    '#8b3bdb',
    '#eaa052',
    '#ef025d',
    '#d85863',
    '#f40c23',
    '#5686ce',
    '#af5de2',
    '#c721d3',
    '#5ee0a3',
    '#4deaea',
    '#308ea8',
    '#c46b13',
    '#8de253',
    '#194596',
    '#4190a8',
    '#d1f738',
    '#c9ff0a',
    '#79e24f',
    '#5c6ef9',
    '#090284',
    '#cf5cf2',
    '#fca955',
    '#4af9e5',
    '#1b8403',
    '#54d394',
    '#3ef73b',
    '#0300d8',
    '#5218f2',
    '#c91acc',
    '#6453bc',
    '#27ce43',
    '#41d399',
    '#44fc56',
    '#ea8a5d',
    '#ce1c93',
    '#f45f89',
    '#3a3ea3',
    '#bdff19',
    '#2ec962',
    '#e22b80',
    '#fc3fa7',
    '#5be580',
    '#291d84',
    '#59e076',
    '#d935f2',
    '#bf342a',
    '#edd51e',
    '#f76260',
    '#49d8c3',
    '#61228e',
    '#1d8cc4',
    '#fc11aa',
    '#46dbd3',
    '#4f97c1',
    '#eada46',
    '#013a66',
    '#1f72d1',
    '#85a30d',
    '#d1663c',
    '#09870d',
    '#ad1b34',
    '#28cc3e',
    '#41c653',
    '#a51f2a',
    '#d5e549',
    '#7f53c1',
    '#e9f76a',
    '#d10a63',
    '#1ead11',
    '#5c8bf9',
    '#d55ce8',
    '#d84e69',
    '#f4de61',
    '#cc1665',
  ],
};
