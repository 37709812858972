import Colors from '../../styles/Colors';

const OnboardingDialogStyles = () => ({
  root: {
    width: '90%',
    marginLeft: '5%',
    marginBottom: 20,
  },
  buttonGroups: {
    marginTop: 20,
  },
  logo: {
    width: 300,
  },
  title: {
    marginLeft: '44%',
  },
  image: {
    fontSize: 100,
    color: Colors.green3,
  },
  popupButton: {
    '&:hover': {
      backgroundColor: Colors.green4,
    },
    backgroundColor: Colors.green3,
    color: Colors.trueWhite,
    width: 130,
  },
  gridContainer: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 0,
  },
  stepLabel: {
    marginTop: 5,
  },
});

export default OnboardingDialogStyles;
