import {
  DELETE_SECTION_STATUS,
  RESET_ANSWER_QUESTIONS,
  SET_ANSWER,
  SET_CURRENT_QUESTION,
  SET_CURRENT_SECTION,
  SET_QUESTIONNAIRE,
  SET_SECTION_STATUS,
  SET_SECTION_STATUSES,
  SET_SELECTED_QUESTIONS,
} from '../action-types/answerQuestionsTypes';

const initialState = {
  statusLookup: null,
  currentSection: null,
  currentQuestion: null,
  questionnaire: null,
  selectedQuestions: [],
};

function answerQuestionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ANSWER: {
      const { sectionId, questionId, answer } = action.payload;
      return {
        ...state,
        currentQuestion: {
          ...state.currentQuestion,
          answer,
        },
        statusLookup: {
          ...state.statusLookup,
          [sectionId]: {
            ...state.statusLookup[sectionId],
            questions: state.statusLookup[sectionId].questions.map((q) => {
              if (q._id === questionId) {
                return { ...q, answer };
              }
              return q;
            }),
          },
        },
        currentSection: {
          ...state.currentSection,
          questions: state.currentSection.questions.map((q) => {
            if (q._id === questionId) {
              return { ...q, answer };
            }
            return q;
          }),
        },
      };
    }
    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload,
      };
    case SET_SECTION_STATUSES:
      return {
        ...state,
        statusLookup: action.payload,
      };
    case SET_SECTION_STATUS: {
      const { sectionId, status } = action.payload;
      return {
        ...state,
        statusLookup: {
          ...state.statusLookup,
          [sectionId]: {
            ...state.statusLookup[sectionId],
            status: status,
          },
        },
      };
    }
    case SET_SELECTED_QUESTIONS:
      return {
        ...state,
        selectedQuestions: action.payload,
      };
    case DELETE_SECTION_STATUS:
      return {
        ...state,
        statusLookup: Object.entries(state.statusLookup).reduce(
          (acc, [sectionId, sectionStatus]) => {
            if (sectionId !== action.payload) {
              acc[sectionId] = sectionStatus;
            }
            return acc;
          },
          {}
        ),
      };
    case SET_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: action.payload,
      };
    case RESET_ANSWER_QUESTIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default answerQuestionsReducer;
