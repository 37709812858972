const isLocal = window.location.host.includes('localhost');
export const FAST_TRACK_URL = isLocal
  ? `${window.location.protocol}//${window.location.host.split(':')[0]}:5000/ft`
  : `${window.location.protocol}//${window.location.host}/ft`;

// API
const API_ENDPOINT_PREFIX = '/api';

// CONFIG
export const API_CONFIG_ENDPOINT = `${API_ENDPOINT_PREFIX}/configuration/config`;
export const API_CONFIG_ENDPOINT_NA = `${API_ENDPOINT_PREFIX}/configuration/config-na`;

// TENANT MANAGEMENT
export const TENANT_MANAGEMENT_ENDPOINT = `${API_ENDPOINT_PREFIX}/tenant-management`;
export const TENANT_MANAGEMENT_CURRENT_ENDPOINT = `${TENANT_MANAGEMENT_ENDPOINT}/current`;
export const TENANT_MANAGEMENT_LIST_ENDPOINT = `${TENANT_MANAGEMENT_ENDPOINT}/list`;
export const TENANT_MANAGEMENT_CHANGE_ENDPOINT = (id) =>
  `${TENANT_MANAGEMENT_ENDPOINT}/change/${id}`;

// SETTINGS
export const SETTINGS_ENDPOINT = `${API_ENDPOINT_PREFIX}/settings`;
export const SETTINGS_INDEX_SYNC_ENDPOINT = `${SETTINGS_ENDPOINT}/syncIndexes`;

// CONTRACTS
export const CONTRACTS_ENDPOINT = `${API_ENDPOINT_PREFIX}/contracts/`;
export const CONTRACT_DELETE_ENDPOINT = `${API_ENDPOINT_PREFIX}/contract/`;
export const CONTRACT_COMPARE_ENDPOINT = `${CONTRACTS_ENDPOINT}compare`;
export const CONTRACT_ISCHECKED_ENDPOINT = `${API_ENDPOINT_PREFIX}/contract/updateChecked`;
export const CONTRACT_SLOTS_ENDPOINT = `${API_ENDPOINT_PREFIX}/contract/updateSlots`;
export const CONTRACT_ISPLUGIN_UNREVIEWED_ENDPOINT = `${API_ENDPOINT_PREFIX}/contract/updateIsPluginUnreviewed`;

const DD_API_BASE_URI = isLocal
  ? `${window.location.protocol}//${window.location.host.split(':')[0]}:5001`
  : `${window.location.protocol}//${window.location.host}`;
const DD_API_ENDPOINT_PREFIX = `${DD_API_BASE_URI}/rd/api`;
export const CONTRACTS_LIST_ENDPOINT = `${DD_API_ENDPOINT_PREFIX}/contracts`;
export const REPORT_LIST_ENDPOINT = `${DD_API_ENDPOINT_PREFIX}/reportList`;
export const REPORT_ENDPOINT = `${DD_API_ENDPOINT_PREFIX}/report`;
export const MIGRATE_REPORT_ENDPOINT = `${DD_API_ENDPOINT_PREFIX}/migrateReport`;
export const USER_EDITS_REPORT_ENDPOINT = `${DD_API_ENDPOINT_PREFIX}/user-edits-report`;
export const WEBSOCKET_API_BASE_URI = isLocal
  ? `ws://${window.location.host.split(':')[0]}:5001/rd/api`
  : `wss://${window.location.host}/rd/api`;

// GLOBAL EVENTS WEB SOCKET
export const GLOBAL_EVENT_WEB_SOCKET_ENDPOINT = '/global/event';

// CONTRACTS WEB SOCKET
export const CONTRACTS_WEB_SOCKET_ENDPOINT = '/contracts';

// LOGS WEB SOCKET
export const LOGS_WEB_SOCKET_ENDPOINT = '/logs';

// LOGS WEB SOCKET
export const TRAINING_JOBS_WEB_SOCKET_ENDPOINT = '/jobs/training';

// EVENTLOGS
export const EVENTLOGS_ENDPOINT = `${API_ENDPOINT_PREFIX}/eventlogs`;

// FILES
export const FILES_ENDPOINT = `${API_ENDPOINT_PREFIX}/files/`;

// IMAGES
export const IMAGES_ENDPOINT = `${API_ENDPOINT_PREFIX}/images`;

// RESOURCE DOWNLOADS
export const RESOURCES_ENDPOINT_PREFIX = `${API_ENDPOINT_PREFIX}`;
export const USERGUIDE_ENDPOINT = `${RESOURCES_ENDPOINT_PREFIX}/userguide`;

// SUGGESTIONS
export const SUGGESTIONS_ENDPOINT = `${API_ENDPOINT_PREFIX}/suggestions`;

// COMPARISONS
export const COMPARISONS_ENDPOINT = `${API_ENDPOINT_PREFIX}/comparisons`;

// USERS
export const USERS_ENDPOINT = `${API_ENDPOINT_PREFIX}/users`;
export const USER_ACCOUNT_RESET_PASSWORD = `${USERS_ENDPOINT}/reset-password`;
export const USER_INFO_TRACK_ENDPOINT = `${API_ENDPOINT_PREFIX}/user/info/track`;
export const CURRENT_USER_ENDPOINT = `${API_ENDPOINT_PREFIX}/current-user`;
export const USER_NEW_ENDPOINT = `${API_ENDPOINT_PREFIX}/users/add-user`;
export const USER_UPDATE_ENDPOINT = `${API_ENDPOINT_PREFIX}/users/update-user`;

// USERS EMAILS
export const USER_EMAILS_ENDPOINT = `${API_ENDPOINT_PREFIX}/user-emails/`;

// ACCOUNT GROUPS
export const ACCOUNT_GROUPS_ENDPOINT = `${API_ENDPOINT_PREFIX}/account-groups/`;

// USER SETTINGS
export const USER_SETTINGS_ENDPOINT = `${API_ENDPOINT_PREFIX}/users/settings`;

// GROUPS
export const GROUPS_ENDPOINT = `${API_ENDPOINT_PREFIX}/groups`;
export const GROUPS_ASSIGNED_ENDPOINT = `${API_ENDPOINT_PREFIX}/groups/assigned`;
export const GROUPS_CLEANUP_ENDPOINT = `${GROUPS_ENDPOINT}/cleanup`;

// MODELS
export const MODELS_ENDPOINT = `${API_ENDPOINT_PREFIX}/trainingmodels`;
export const MODELS_RULES_ENDPOINT = (id) =>
  `${API_ENDPOINT_PREFIX}/trainingmodels/${id}/rules`;
export const MODELS_ALL_FILTERED_ENDPOINT = `${API_ENDPOINT_PREFIX}/trainingmodels-all-filtered`;
export const MODELS_ALL_ENDPOINT = `${API_ENDPOINT_PREFIX}/trainingmodels-all`;
export const MODELS_FILE_ENDPOINT = `${MODELS_ENDPOINT}/upload`;
export const MODELS_BASICS_ENDPOINT = (id) => `${MODELS_ENDPOINT}/${id}/basics`;
export const MODELS_COUNT_TRAINING_ENDPOINT = (id) =>
  `${MODELS_ENDPOINT}/${id}/count-training`;
export const MODELS_UNASSIGNED_TRAINING_ENDPOINT = (id) =>
  `${MODELS_ENDPOINT}/${id}/unassigned/training`;
export const MODELS_DOWNLOAD_WEB_SOCKET_ENDPOINT = '/trainingmodels/download'; // NOTE: No /api prefix here
export const MODELS_DOWNLOAD_TSV_WEB_SOCKET_ENDPOINT = '/trainingmodels/download-tsv'; // NOTE: No /api prefix here
export const MODELS_DOWNLOAD_TSV_FILE_ENDPOINT = (id) =>
  `${MODELS_ENDPOINT}/${id}/download-tsv-file`;

// QUESTIONS
export const QUESTIONS_ENDPOINT = `${API_ENDPOINT_PREFIX}/questions`;

// INTEGRATIONS
export const INTEGRATIONS_ENDPOINT = `${API_ENDPOINT_PREFIX}/integrations`;
export const INTEGRATION_AUTHORIZE_ENDPOINT = `${INTEGRATIONS_ENDPOINT}/authorize`;
export const INTEGRATION_AUTH_ENDPOINT = `${INTEGRATIONS_ENDPOINT}/auth`;
export const INTEGRATION_REGISTER_ENDPOINT = `${INTEGRATIONS_ENDPOINT}/register`;

// QUESTIONNAIRES
export const QUESTIONNAIRES_ENDPOINT = `${API_ENDPOINT_PREFIX}/questionnaires`;
export const QUESTIONNAIRES_DEFAULT_ENDPOINT = `${API_ENDPOINT_PREFIX}/questionnaires/default`;
export const QUESTIONNAIRES_LIST_ENDPOINT = `${API_ENDPOINT_PREFIX}/questionnaires/list`;
export const QUESTIONNAIRES_MOVE_ENDPOINT = (id) =>
  `${QUESTIONNAIRES_ENDPOINT}/${id}/move`;
export const QUESTIONNAIRES_ADD_SECTION_ENDPOINT = (id) =>
  `${QUESTIONNAIRES_ENDPOINT}/${id}/add-section`;

// TRAINING
const TRAINING_ENDPOINT = `${API_ENDPOINT_PREFIX}/training`;
export const TRAINING_MODIFY_EDITS_ENDPOINT = `${TRAINING_ENDPOINT}/modifyEdits`;
export const TRAINING_DELETE_BY_IDS_ENDPOINT = `${TRAINING_ENDPOINT}/deleteByIds`;
export const TRAINING_CREATE_FROM_ZIP_ENDPOINT = `${TRAINING_ENDPOINT}/create/fromZip`;
export const TRAINING_ATTRIBUTES_ENDPOINT = `${TRAINING_ENDPOINT}/attributes`;

// JOBS
const JOBS_ENDPOINT = `${API_ENDPOINT_PREFIX}/jobs`;

// TRAINING JOBS
export const TRAINING_JOBS_GET_ENDPOINT = `${JOBS_ENDPOINT}/training`;

// TRAINING RULES
export const TRAINING_RULES_ENDPOINT = `${API_ENDPOINT_PREFIX}/training/rules`;
export const TRAINING_RULES_LEAF_LIST_EDIT_ENDPOINT = `${TRAINING_RULES_ENDPOINT}/leafListEdit`;
export const TRAINING_RULES_POINT_EDIT_ENDPOINT = `${TRAINING_RULES_ENDPOINT}/pointEdit`;
export const TRAINING_RULES_FXI_ENDPOINT = `${TRAINING_RULES_ENDPOINT}/fxi`;
export const TRAINING_RULES_IMPORT_RULES_ENDPOINT = `${TRAINING_RULES_ENDPOINT}/importRules`;
export const TRAINING_RULES_ANSWER_QUESTIONS_ENDPOINT = `${TRAINING_RULES_ENDPOINT}/answerQuestions`;

// TRAINING RULE SECTIONS
export const TRAINING_RULE_SECTIONS_ENDPOINT = `${API_ENDPOINT_PREFIX}/training/rule/sections`;

// TEXT
export const TEXT_ENDPOINT = `${API_ENDPOINT_PREFIX}/text`;

// IMPORT RULES
export const IMPORT_RULES_ENDPOINT = `${API_ENDPOINT_PREFIX}/import-rules`;

// COMPANIES
export const COMPANIES_ENDPOINT = `${API_ENDPOINT_PREFIX}/companies`;

// ONLY OFFICE
export const ONLYOFFICE_CONFIG_ENDPOINT = `${API_ENDPOINT_PREFIX}/onlyoffice/config`;
export const ONLYOFFICE_TRACK_ENDPOINT = `${API_ENDPOINT_PREFIX}/onlyoffice/track`;

// ONLY OFFICE PLUGIN
export const ONLYOFFICE_PLUGIN_ENDPOINT = `${API_ENDPOINT_PREFIX}/plugin`;

// CLAUSES
export const CLAUSES_ENDPOINT = `${API_ENDPOINT_PREFIX}/clauses`;
export const CLAUSES_DELETE_ENDPOINT = `${API_ENDPOINT_PREFIX}/clauses/`;
export const CLAUSE_CATEGORIES_ENDPOINT = `${API_ENDPOINT_PREFIX}/clause-categories`;

// METRICS
export const METRICS_ENDPOINT = `${API_ENDPOINT_PREFIX}/metrics`;

// EVENTS
export const EVENTS_ENDPOINT = `${API_ENDPOINT_PREFIX}/events`;
export const EVENTS_RECENT_WORK_ENDPOINT = `${API_ENDPOINT_PREFIX}/events/recentwork`;
export const EVENTS_BLACKBOILER_ENDPOINT = `${API_ENDPOINT_PREFIX}/events/analytics`;
export const EVENTS_FASTTRACK_ENDPOINT = `${API_ENDPOINT_PREFIX}/events/fasttrack`;
export const EVENTS_DOCUSIGN_ENDPOINT = `${EVENTS_FASTTRACK_ENDPOINT}/docusign`;

// FAST TRACK
export const FAST_TRACK_ENDPOINT = `${API_ENDPOINT_PREFIX}/fasttrack`;
export const FAST_TRACK_FORMFILL_ENDPOINT = `${FAST_TRACK_URL}/formfill`;
export const FAST_TRACK_SIGNING_ENDPOINT = `${FAST_TRACK_URL}/ds`;
export const FAST_TRACK_POA_ENDPOINT = `${FAST_TRACK_URL}/poa`;
export const FAST_TRACK_POA_RENEWAL_ENDPOINT = `${FAST_TRACK_URL}/poa_renewal`;
export const FAST_TRACK_POA_LIST_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/list`;
export const FAST_TRACK_POA_DOC_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/doc`;
export const FAST_TRACK_POA_HELP_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/help`;
export const FAST_TRACK_POA_DOWNLOAD_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/download`;
export const FAST_TRACK_POA_ATTACHMENT_DOWNLOAD_ENDPOINT = (
  submissionId,
  attachmentName
) => `${FAST_TRACK_POA_ENDPOINT}/attachment/${submissionId}/${attachmentName}`;
export const FAST_TRACK_POA_UPLOADGEMS_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/gems`;
export const FAST_TRACK_POA_ACTIVEPOA_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/active`;
export const FAST_TRACK_POA_NOTIFY_ENDPOINT = `${FAST_TRACK_POA_ENDPOINT}/notify`;

// POA GEMS
export const FAST_TRACK_POA_GEMS_ENDPOINT = `${API_ENDPOINT_PREFIX}/poa-gems`;

// MIGRATION
export const MIGRATION_ENDPOINT = `${API_ENDPOINT_PREFIX}/account-migration`;
export const ROUTING_MIGRATION_ENDPOINT = `${API_ENDPOINT_PREFIX}/routing-migration`;

// LOGGING
export const LOG_ENDPOINT = `${API_ENDPOINT_PREFIX}/logging/log`;
export const LOG_LOGIN_ENDPOINT = `${API_ENDPOINT_PREFIX}/logging/login`;
export const LOG_LOGOUT_ENDPOINT = `${API_ENDPOINT_PREFIX}/logging/logout`;

// /BBWEB PREFIXED FOR PLUGIN
const BB_WEB_PREFIX = '/bbweb';
export const BBWEB_PREFIXED_CONTRACTS_ENDPOINT = `${BB_WEB_PREFIX}${CONTRACTS_ENDPOINT}`;

// FIND SIMILAR
export const FIND_SIMILAR_ENDPOINT = `${API_ENDPOINT_PREFIX}/similarity`;
export const FIND_SIMILAR_GENERAL_ENDPOINT = `${FIND_SIMILAR_ENDPOINT}/general`;
export const FIND_SIMILAR_PRESENCE_ENDPOINT = `${FIND_SIMILAR_ENDPOINT}/presence`;
export const FIND_SIMILAR_CONTEXT_ENDPOINT = `${FIND_SIMILAR_ENDPOINT}/context`;
export const FIND_SIMILAR_EDIT_ENDPOINT = `${FIND_SIMILAR_ENDPOINT}/edit`;

// ACCOUNT
export const ACCOUNT_ENDPOINT = `${API_ENDPOINT_PREFIX}/account`;
// NOTE: Deactivate changing tier type for a while
// export const ACCOUNT_TIER_CHANGE_ENDPOINT = `${ACCOUNT_ENDPOINT}/tier-change`;
export const ACCOUNT_UPDATE_UPLOADED_CONTRACTS_ENDPOINT = `${ACCOUNT_ENDPOINT}/update-uploaded-contracts`;

// TIER COMPARISON
export const TIER_COMPARISONS_ENDPOINT = `${API_ENDPOINT_PREFIX}/tier-comparisons`;

// WEBHOOKS
const WEBHOOK_ENDPOINT = `${API_ENDPOINT_PREFIX}/webhook`;
export const WEBHOOK_EXPORT_CONTRACT_ENDPOINT = (id) =>
  `${WEBHOOK_ENDPOINT}/export-contract/${id}`;

// DISTRIBUTED TRACING
export const ERROR_REPORTING_ENDPOINT = `${API_ENDPOINT_PREFIX}/trace/error`;
export const LOG_REPORTING_ENDPOINT = `${API_ENDPOINT_PREFIX}/trace/log`;
