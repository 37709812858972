import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import {
  SetSectionStatus,
  SetCurrentQuestion,
} from '../../redux/actions/answerQuestionsActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { GetQuestionTypes } from '../../requests/question';
import { SECTION_STATUS } from '../AnswerQuestionsStatus/constants';
import { YesNo } from '../YesNo';
import { YesNoTextEditor } from '../YesNoTextEditor';
import { PlaybookBuilderPage } from '../PlaybookBuilderPage';
import FillInTheBlank from './FillInTheBlank';
import MultiSelectGovLaw from './MultiSelectGovLaw';

function AnswerQuestionStepController({ handleBack }) {
  const { getAuthHeader } = useAuthUpdateContext();

  const dispatch = useDispatch();
  const setErr = (value) => dispatch(setErrMsg(value));

  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );
  const [questionTypes, setQuestionTypes] = useState();
  const [currentIdx, setCurrentIdx] = useState(0);

  useEffect(() => {
    getAuthHeader().then((headers) => {
      GetQuestionTypes(headers)
        .then((data) => setQuestionTypes(data))
        .catch((err) => {
          setErr('Failed to retrieve question types');
          console.error(err);
        });
    });
  }, []);

  function submit() {
    const { questions } = currentSection;
    const allAnswered = questions.every((q) => q.answer != undefined && q.answer != null);
    if (allAnswered) {
      dispatch(SetSectionStatus(currentSection._id, SECTION_STATUS.COMPLETE));
    }
    handleBack();
  }

  function backLink() {
    if (currentIdx === 0) {
      handleBack();
    } else {
      setCurrentIdx(currentIdx - 1);
    }
  }

  function nextLink() {
    if (currentIdx === currentSection.questions.length - 1) {
      submit();
    } else {
      const currentQuestion = currentSection.questions[currentIdx + 1];
      setCurrentIdx(currentIdx + 1);
      dispatch(SetCurrentQuestion(currentQuestion));
    }
  }

  if (!questionTypes) {
    return null;
  }

  return (
    <PlaybookBuilderPage
      title={currentQuestion.text}
      extendedTitle={currentQuestion.extendedTitle}
      description={currentQuestion.description}
      currentStep={currentIdx + 1}
      totalSteps={currentSection.questions.length}
      lButtonOnClick={backLink}
      lButtonText={currentIdx === 0 ? 'Back to Status' : 'Back'}
      rButtonOnClick={nextLink}
      rButtonText={
        currentIdx === currentSection.questions.length - 1 ? 'Finish Section' : 'Next'
      }
      rButtonDisabled={false}
    >
      {(() => {
        switch (currentQuestion.type) {
          case questionTypes.YES_NO:
            return <YesNo />;
          case questionTypes.YES_NO_TEXT_EDITOR:
            return <YesNoTextEditor />;
          case questionTypes.MULTI_SELECT_JURISDICTION:
            return <MultiSelectGovLaw />;
          case questionTypes.FILL_IN_THE_BLANK:
            return <FillInTheBlank />;
          default:
            throw new Error(`Unsupported question type ${currentQuestion?.type}`);
        }
      })()}
    </PlaybookBuilderPage>
  );
}

AnswerQuestionStepController.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default AnswerQuestionStepController;
