import React, { useState } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Checkbox, Typography, Button, Grid } from '@mui/material';

import BucketedCheckboxDisplayStyles from './BucketedCheckboxDisplayStyles';

const BucketedCheckboxDisplay = ({
  classes,
  key = 'bucketedCheckboxDisplay',
  selectAllText,
  buckets = [],
  setBuckets = (f) => f,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const checkAllSelected = (allBuckets = buckets) => {
    const { total, numChecked } = allBuckets.reduce(
      (acc, bucket) => {
        let tmpTotal = 0;
        let tmpNumChecked = 0;
        bucket.forEach(({ checked }) => {
          if (checked) {
            tmpNumChecked += 1;
          } else {
            tmpNumChecked -= 1;
          }
          tmpTotal += 1;
        });
        return {
          total: acc.total + tmpTotal,
          numChecked: acc.numChecked + tmpNumChecked,
        };
      },
      { total: 0, numChecked: 0 }
    );

    return total === numChecked;
  };

  const handleCheck = (name, bucketIdx, nameIdx) => {
    const newBuckets = [...buckets];
    newBuckets[bucketIdx][nameIdx].checked = !newBuckets[bucketIdx][nameIdx].checked;
    setBuckets(newBuckets);

    const allChecked = checkAllSelected(newBuckets);
    if (allChecked) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = () => {
    const shouldSelectAll = !selectAll;
    const newBuckets = buckets.map((bucket) =>
      bucket.map(({ name }) => ({ name, checked: shouldSelectAll }))
    );
    setBuckets(newBuckets);
    setSelectAll(shouldSelectAll);
  };

  return (
    <div key={key}>
      <Typography>
        <Button className={classes.selectAll} onClick={handleSelectAll}>
          <Checkbox color="primary" name={selectAllText} disabled checked={selectAll} />
          <Typography>{selectAllText}</Typography>
        </Button>
      </Typography>
      <Grid container spacing={2}>
        {!!buckets && !!buckets.length
          ? buckets.map((bucket, bucketIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={3} key={`bucket-${bucketIdx}`}>
                <Grid container direction="column">
                  {bucket.map(({ name, checked }, nameIdx) => (
                    <Grid item key={name} className={classes.checkboxGridItemWrapper}>
                      <Button
                        className={classes.checkboxButtonWrapper}
                        onClick={() => handleCheck(name, bucketIdx, nameIdx)}
                      >
                        <Grid container alignItems="center">
                          <Grid item>
                            <Checkbox
                              color="primary"
                              disabled
                              checked={checked}
                              name={name}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" className={classes.sectionText}>
                              {name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  );
};

BucketedCheckboxDisplay.propTypes = {
  classes: PropTypes.any.isRequired,
  key: PropTypes.string,
  selectAllText: PropTypes.string.isRequired,
  buckets: PropTypes.array,
  setBuckets: PropTypes.func,
};

export default withStyles(BucketedCheckboxDisplayStyles)(BucketedCheckboxDisplay);
