import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import { Grid, Typography, MenuItem, Select } from '@mui/material';

import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import BucketedCheckboxDisplay from './BucketedCheckboxDisplay';
import MultiSelectGovLawStyles from './MultiSelectGovLawStyles';

function MultiSelectGovLaw() {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (
      !options.length &&
      !!currentQuestion.answer &&
      !!currentQuestion.answer.groups &&
      currentQuestion.answer.groups.length
    ) {
      setOptions(currentQuestion.answer.groups.map(({ items }) => items).flat());
    }
  }, [currentQuestion.answer]);

  function handleSetBuckets(newBuckets, groupIdx) {
    const newGroups = [...currentQuestion.answer.groups];
    newGroups[groupIdx].buckets = newBuckets;
    dispatch(
      SetAnswer(currentSection._id, currentQuestion._id, {
        ...currentQuestion.answer,
        groups: newGroups,
      })
    );
  }

  function setSelectedOption(selection) {
    dispatch(
      SetAnswer(currentSection._id, currentQuestion._id, {
        ...currentQuestion.answer,
        selectedOption: selection,
      })
    );
  }

  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 10 }}>
      <Grid item>
        {options.length ? (
          <Select
            variant="outlined"
            displayEmpty
            value={currentQuestion.answer?.selectedOption || ''}
            inputProps={{
              'aria-label': 'Insert Slot',
            }}
            onChange={(evt) => setSelectedOption(evt.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            style={{ width: '30%' }}
          >
            <MenuItem value="" disabled>
              - {currentQuestion.answer.promptText || ''} -
            </MenuItem>
            {options.length
              ? options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              : null}
          </Select>
        ) : null}
      </Grid>
      <Grid item>
        <Typography variant="h2" style={{ fontWeight: 'bold' }}>
          Select all acceptable jurisdictions below
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          States or provinces that are NOT selected will be replaced with your preferred
          jurisdiction above.
        </Typography>
      </Grid>
      <Grid item>
        {!!currentQuestion.answer?.groups.length &&
          currentQuestion.answer.groups.map(({ title, buckets }, groupIdx) => (
            <BucketedCheckboxDisplay
              key={title}
              selectAllText={`Select all ${title}`}
              buckets={buckets}
              setBuckets={(newBuckets) => handleSetBuckets(newBuckets, groupIdx)}
            />
          ))}
      </Grid>
    </Grid>
  );
}

export default withStyles(MultiSelectGovLawStyles)(MultiSelectGovLaw);
