import {
  SET_INTAKE_FORM_VALUES,
  SET_FIELD_DEPENDENCY_MAP,
} from '../action-types/poaTypes';

const initialState = {
  intakeFormValues: {},
  dependencyMap: {},
};

export default function poaReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTAKE_FORM_VALUES:
      return { ...state, intakeFormValues: action.payload };

    case SET_FIELD_DEPENDENCY_MAP:
      return { ...state, dependencyMap: action.payload };

    default:
      return state;
  }
}
