const isValid = (redirectPath) =>
  !redirectPath || (redirectPath && redirectPath.startsWith('/bbweb'));

const searchParamsToMap = (params) =>
  Array.from(new URLSearchParams(params)).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

export const getRedirect = (search) => {
  /*
    Validate the path, but preserve all query string params
    in:
        - ?redirect=/bbweb/home
        - ?redirect=/bbweb/home?me=5&you=6
    out: /bbweb/home?redirect=/bbweb/home&me=5&you=6
  */
  const numQs = (search.match(/\?/g) || []).length;

  const [, query1, query2] = search.split('?');
  const query = numQs > 1 ? `${query1}&${query2}` : query1;

  const searchMap = searchParamsToMap(query);
  // Filter code and state
  delete searchMap.code;
  delete searchMap.state;

  const valid = isValid(searchMap.redirect);
  const newParams = Object.entries(searchMap).reduce((acc, [k, v]) => {
    const updatedStr = `${acc}${acc.length ? '&' : ''}${k}=${v}`;
    if (valid || (!valid && k !== 'redirect')) {
      return updatedStr;
    }
    return acc;
  }, '');

  let combined = valid ? `${searchMap.redirect}` : '/bbweb/home';
  if (newParams.length) {
    combined = `${combined}?${newParams}`;
  }

  return {
    queryMap: searchMap,
    path: combined,
  };
};

export const withoutRedirect = (url) => {
  const [urlOnly, params] = url.split('?');
  const paramsObj = new URLSearchParams(params);
  paramsObj.delete('redirect');
  return `${urlOnly}?${paramsObj.toString()}`;
};
