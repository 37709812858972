// Convert camelCase and snake_case variable to Title
// thisName => This Name, this_name => This Name
export const convertToTitle = (str) => {
  if (str.includes('_')) {
    return str
      .split('_')
      .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
      .join(' ');
  }

  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getSimModelName = (path) => {
  if (path) {
    const pathSplit = path.split('/');
    return pathSplit[pathSplit.length - 1].split('.')[0];
  }
  return path;
};

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getAnalyticRouteName = (name) => {
  switch (name) {
    case 'business_norisk':
      return 'No Risk';
    case 'business_risk_lowcoverage':
      return 'Low Coverage';
    case 'legal':
      return 'Risk';
    case 'tst':
      return 'Test';
    default:
      console.log(`Failed get analytic route name with ${name}`);
      return '';
  }
};

export const convertToValidKeyForDomain = (domain) => domain.split('.').join('_');
export const revertKeyForDomain = (domain) => domain.split('_').join('.');
