import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Select,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

import ContractIcon from '../../assets/images/contract_icon.png';
import ModalsStyles from '../../styles/Modals';
import CombineStyles from '../../utils/CombineStyles';
import { LabeledSelect } from '../LabeledSelect';
import ConfirmSaveRuleModalStyles from './styles';

const ConfirmSaveRuleModal = ({
  classes,
  ruleName = '',
  ruleDescription = '',
  setRuleName,
  saveRuleModalOpen,
  setSaveRuleModalOpen,
  ruleComment,
  setRuleComment,
  ruleData = { name: '', description: '' },
  setRuleDescription,
  confirmSaveRule,
  getEditCountText = null,
  ruleOrder,
  setRuleOrder,
  existingComments,
  ruleSection = null,
  setRuleSection = (f) => f,
  availableSections = [],
}) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const rule = urlSearchParams.get('rule');

  const [commentSelection, setCommentSelection] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const handleCommentSelect = (e) => {
    setCommentSelection(e.target.value);
    setRuleComment(e.target.value);
  };

  const clearCommentSelect = () => {
    setCommentSelection('');
    setRuleComment('');
  };

  const closeSaveRuleModal = () => {
    setRuleName(rule ? ruleData.name : '');
    setRuleDescription(rule ? ruleData.description : '');
    setRuleComment('');
    setSaveRuleModalOpen(false);
  };

  const handleSave = () => {
    setShowSpinner(true);
    confirmSaveRule();
    return () => setShowSpinner(false);
  };

  return (
    <Modal
      open={saveRuleModalOpen}
      onClose={closeSaveRuleModal}
      aria-label="Save rule"
      className={classes.modalWrapper}
    >
      <div className={classes.modalContentWrapper}>
        <div className={`container ${classes.modalHeader} pb-2 pt-4`}>
          <div className="row pb-4 text-center">
            <div className="col pt-3 pl-5 pr-0">
              <img
                src={ContractIcon}
                alt=""
                className={`${classes.modalModelImg} pb-4`}
              />
              <Typography variant="h2">
                {rule ? 'Update' : 'Create'} Rule{' '}
                {getEditCountText ? `(${getEditCountText()})` : ''}
              </Typography>
            </div>
            <div className="col-auto d-flex flex-column justify-content-start">
              <IconButton
                color="inherit"
                aria-label="Close create rule modal"
                onClick={closeSaveRuleModal}
                edge="end"
                autoFocus
                size="large"
              >
                <CloseIcon className={classes.modalCloseIcon} />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="container pl-5 pr-5 pt-3 pb-3">
          {availableSections.length > 0 ? (
            <div className="row pt-3 pb-3">
              <div className="col">
                <LabeledSelect
                  setValue={setRuleSection}
                  title="Section"
                  value={ruleSection}
                  items={availableSections}
                />
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col">
              <strong>Name:</strong>
              <TextField
                id="text"
                key="rule_name"
                variant="outlined"
                placeholder="Name your rule here..."
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  'aria-label': 'Rule Name TextField',
                }}
                value={ruleName}
                onChange={(evt) => {
                  setRuleName(evt.target.value);
                }}
              />
            </div>
            <div className="col">
              <strong>Description:</strong>
              <TextField
                id="text"
                key="rule_description"
                variant="outlined"
                placeholder="Describe your rule here..."
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  'aria-label': 'Rule Description TextField',
                }}
                value={ruleDescription}
                onChange={(evt) => {
                  setRuleDescription(evt.target.value);
                }}
              />
            </div>
          </div>
          <Divider light style={{ marginTop: 12, marginBottom: 10 }} />
          {existingComments.length > 0 ? (
            <>
              <div className="row">
                <div className="col">
                  <Typography>(Optional) Existing Comments for this rule:</Typography>
                </div>
              </div>
              <div className="row">
                <div className="col-9">
                  <Select
                    variant="outlined"
                    displayEmpty
                    value={commentSelection}
                    inputProps={{
                      'aria-label': 'Select Existing Comment',
                    }}
                    renderValue={(option) => {
                      if (option && existingComments.length > 0) {
                        return `Existing Comment: ${option}`;
                      }
                      return 'Select: Existing Comment';
                    }}
                    onChange={handleCommentSelect}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select: Existing Comment
                    </MenuItem>
                    {existingComments.map((comment) => (
                      <MenuItem
                        key={comment}
                        value={comment}
                        className={classes.menuItem}
                      >
                        {comment}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col-3 align-center align-middle">
                  <Button
                    className={classes.clearCommentButton}
                    onClick={clearCommentSelect}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </>
          ) : null}
          <div className="row mt-3">
            <div className="col">
              <Typography>(Optional) Comment for edits:</Typography>
              <TextField
                id="text"
                key="rule_comment"
                variant="outlined"
                placeholder="Add your comment for the edits here..."
                fullWidth
                multiline
                rows={2}
                inputProps={{
                  'aria-label': 'Rule Comment TextField',
                }}
                value={ruleComment}
                onChange={(evt) => {
                  setRuleComment(evt.target.value);
                }}
              />
            </div>
          </div>
          <Divider light style={{ marginTop: 12, marginBottom: 10 }} />
          <div className="row mt-3">
            <div className="col-4">
              <Typography>(Optional) Order:</Typography>
              <TextField
                onChange={(e) => setRuleOrder(e.currentTarget.valueAsNumber)}
                name="rule_order"
                type="number"
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: '0',
                    max: '1000',
                    step: '1',
                    style: {
                      textAlign: 'center',
                    },
                  },
                }}
                value={ruleOrder}
              />
            </div>
          </div>
        </div>
        <div className="container pl-5 pr-5">
          <div className="row pt-5 pb-4">
            {showSpinner ? (
              <div className={`col ${classes.loadingWrapper}`}>
                <CircularProgress size="50px" />
              </div>
            ) : (
              <>
                <div className="col">
                  <Button variant="outlined" fullWidth onClick={closeSaveRuleModal}>
                    Cancel
                  </Button>
                </div>
                <div className="col">
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={
                      !saveRuleModalOpen || !ruleName || !ruleDescription || !ruleSection
                    }
                    onClick={handleSave}
                  >
                    {rule ? 'Update' : 'Save'} Rule
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ConfirmSaveRuleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  ruleName: PropTypes.string,
  ruleDescription: PropTypes.string,
  setRuleName: PropTypes.func.isRequired,
  setRuleDescription: PropTypes.func.isRequired,
  saveRuleModalOpen: PropTypes.bool.isRequired,
  setSaveRuleModalOpen: PropTypes.func.isRequired,
  ruleComment: PropTypes.string.isRequired,
  setRuleComment: PropTypes.func.isRequired,
  ruleData: PropTypes.object,
  confirmSaveRule: PropTypes.func.isRequired,
  getEditCountText: PropTypes.func,
  ruleOrder: PropTypes.number.isRequired,
  setRuleOrder: PropTypes.func.isRequired,
  existingComments: PropTypes.arrayOf(PropTypes.string).isRequired,
  ruleSection: PropTypes.string,
  setRuleSection: PropTypes.func,
  availableSections: PropTypes.array,
};

const combinedStyles = CombineStyles(ModalsStyles, ConfirmSaveRuleModalStyles);
export default withStyles(combinedStyles)(ConfirmSaveRuleModal);
