import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const Flex = ({ children, sx }) => {
  return <Box sx={{ display: 'flex', ...sx }}>{children}</Box>;
};

const FlexH = ({ children, sx }) => {
  return <Flex sx={{ flexDirection: 'row', ...sx }}>{children}</Flex>;
};

const FlexV = ({ children, sx }) => {
  return <Flex sx={{ flexDirection: 'column', width: '100%', ...sx }}>{children}</Flex>;
};

const FLEX_PROPS = {
  children: PropTypes.any,
  sx: PropTypes.object,
};

Flex.propTypes = FLEX_PROPS;
FlexV.propTypes = FLEX_PROPS;
FlexH.propTypes = FLEX_PROPS;

const FlexNotation = Object.assign(Flex, { H: FlexH, V: FlexV });

export default FlexNotation;
