import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { AnswerQuestionSectionSelection } from '../AnswerQuestionSectionSelection';
import { AnswerQuestionsStatus } from '../AnswerQuestionsStatus';
import { AnswerQuestionStepController } from '../AnswerQuestionStepController';
import { LabeledSelect } from '../LabeledSelect';
import OnboardingStepContentStyles from './OnboardingStepContentStyles';

const OnboardingStepContent = ({
  classes,
  step = 0,
  getValues = (f) => f,
  setValue = (f) => f,
  errors = {},
  defaultQuestionnaires = [],
  modelId = null,
  contractTypes = [],
  handleSetActiveStep = (f) => f,
  handleShowResult = (f) => f,
}) => {
  switch (step) {
    case 0:
      return (
        <>
          <FormControl variant="standard" error={errors.name} className={classes.field}>
            <TextField
              variant="standard"
              label="Playbook Name"
              value={getValues('name')}
              onChange={(evt) => setValue('name', evt.target.value)}
            />
            <FormHelperText>{errors.name && errors.name.message}</FormHelperText>
          </FormControl>
          <FormControl
            variant="standard"
            error={errors.contractType}
            className={classes.field}
          >
            <InputLabel>Contract Type</InputLabel>
            {contractTypes.length === 1 ? (
              <Select
                variant="standard"
                fullWidth
                inputProps={{
                  'aria-labelledby': 'contractType-label',
                }}
                value={contractTypes[0].name}
              >
                <MenuItem key={contractTypes[0].name} value={contractTypes[0].name}>
                  {contractTypes[0].label}
                </MenuItem>
              </Select>
            ) : (
              <LabeledSelect
                title="Contract Type"
                keyField="name"
                valueField="label"
                setValue={(contractType) => setValue('contractType', contractType)}
                value={getValues('contractType')}
                items={contractTypes}
                variant="standard"
              />
            )}
            <FormHelperText>
              {errors.contractType && errors.contractType.message}
            </FormHelperText>
          </FormControl>

          {!!defaultQuestionnaires.length && (
            <FormControl variant="standard" error={errors.defaultQuestionnaire}>
              <InputLabel>Questionnaire</InputLabel>
              <LabeledSelect
                title="Questionnaire"
                keyField="name"
                valueField="label"
                setValue={(questionnaire) =>
                  setValue('defaultQuestionnaire', questionnaire)
                }
                value={getValues('defaultQuestionnaire')}
                items={defaultQuestionnaires.map((q) => ({
                  name: q._id,
                  label: `[${q.model}] ${q.name} -- ${q.size} question${q.size === 1 ? '' : 's'}`,
                }))}
                variant="standard"
              />
              <FormHelperText>
                {errors.defaultQuestionnaire && errors.defaultQuestionnaire.message}
              </FormHelperText>
            </FormControl>
          )}
        </>
      );
    case 1:
      return (
        <>
          {modelId && (
            <AnswerQuestionSectionSelection
              modelId={modelId}
              handleNext={() => handleSetActiveStep(2)}
            />
          )}
        </>
      );
    case 2:
      return (
        <>
          {modelId && (
            <AnswerQuestionsStatus
              modelId={modelId}
              onboarding
              handleNext={() => handleSetActiveStep(3)}
              handleBack={() => handleSetActiveStep(1)}
              redirectTo={handleShowResult}
            />
          )}
        </>
      );
    case 3:
      return <AnswerQuestionStepController handleBack={() => handleSetActiveStep(2)} />;
    default:
      return null;
  }
};

OnboardingStepContent.propTypes = {
  classes: PropTypes.object.isRequired,
  step: PropTypes.number,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  defaultQuestionnaires: PropTypes.array,
  modelId: PropTypes.string,
  contractTypes: PropTypes.array,
  handleSetActiveStep: PropTypes.func,
  handleShowResult: PropTypes.func,
};

export default withStyles(OnboardingStepContentStyles)(OnboardingStepContent);
