import Colors from '../../styles/Colors';

const TextEditorStyles = () => ({
  root: {
    padding: 20,
    wordWrap: 'break-word',
    width: 900,
    minHeight: 150,
  },
  frame: {
    backgroundColor: 'white',
    width: '100%',
    padding: 10,
  },
  editor: {
    boxSizing: 'border-box',
    border: '1px solid #ddd',
    cursor: 'text',
    padding: '16px',
    borderRadius: '2px',
    marginBottom: '2em',
    boxShadow: 'inset 0px 1px 8px -3px #ABABAB;',
    background: '#fefefe',
    minHeight: '10em',
  },
  buttonBar: {
    backgroundColor: Colors.grey1,
    marginLeft: 1,
    marginRight: 1,
    marginTop: 1,
    marginBottom: '1em',
    maxWidth: '100%',
  },
  button: {
    '&:hover, &:focus': {
      backgroundColor: `rgba(${Colors.blueRGB}, .1)`,
      '& svg': {
        fill: Colors.blue,
      },
    },
  },
  muiButtonOverride: {
    minHeight: 0,
    minWidth: 0,
    display: 'inline-block',
    padding: 0,
  },
});

export default TextEditorStyles;
