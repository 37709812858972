// FIND SIMILAR
export const FIND_SIMILAR_GENERAL_SIMILARITY_THRESHOLD = 0.8;

// TIMEOUT (milliseconds * seconds * minutes)
export const ABCCORP_IDLE_TIMEOUT = 1000 * 60 * 15;

// UPLOADING BIG FILES CHUNK - EFS
export const chunkSize = 1048576 * 10; // its ~10MB

// CONTRACTS
export const defaultReviewStates = [
  {
    name: 'Under Review',
    label: 'Under Review',
    action: 'set',
    default: true,
  },
  {
    name: 'With Counterparty',
    label: 'With Counterparty',
    action: 'set',
    default: false,
  },
];

export const routeNamesForAnalytics = [
  'tst',
  'business_risk_lowcoverage',
  'business_norisk',
  'legal',
];

// QUESTION TYPE STRINGS
export const QUESTION_TYPE_STRINGS = {
  YES_NO: 'Yes/No',
  YES_NO_TEXT_EDITOR: 'Yes/No with Input',
  MULTI_SELECT: 'Multiple Select',
  MULTI_SELECT_JURISDICTION: 'Multiple Select Jurisdiction',
  FILL_IN_THE_BLANK: 'Fill in the blank',
  LIST_EDIT: 'List Edit',
};

// REPORT NAMES
export const CONTEXT_REPORT_NAME = 'Context';
export const PRESENCE_REPORT_NAME = 'Presence';
export const EDIT_OPERATIONS_REPORT_NAME = 'Edit Operations';
export const ALIGNMENT_REPORT_NAME = 'Alignment';
export const USER_EDITS_REPORT_NAME = 'User Edits';
export const RISK_REPORT_NAME = 'Risk';
export const ISSUE_REPORT_NAME = 'Issue';
export const REPORT_NAMES = [
  ALIGNMENT_REPORT_NAME,
  PRESENCE_REPORT_NAME,
  CONTEXT_REPORT_NAME,
  EDIT_OPERATIONS_REPORT_NAME,
  USER_EDITS_REPORT_NAME,
  ISSUE_REPORT_NAME,
];

// REPORTING GRID GLOBALS
export const ROW_DEFAULT_HEIGHT = 110; // 22 per row

// REPORTING GRID COLUMNS
export const XS_DEFAULT_WIDTH = 120;
export const SM_DEFAULT_WIDTH = 180;
export const M_DEFAULT_WIDTH = 200;
export const L_DEFAULT_WIDTH = 240;
export const XL_DEFAULT_WIDTH = 350;
export const XXL_DEFAULT_WIDTH = 1000;

export const DATE_DEFAULT_WIDTH = 220;
export const SENTENCE_LABEL_DEFAULT_WIDTH = 250;
export const SENTENCE_DEFAULT_WIDTH = 600;

export const TSV_EXPORT_SEPARATOR = '\t';
export const CSV_EXPORT_SEPARATOR = ',';

// For Setting Page
export const contractTypes = [
  { name: 'nda', label: 'NDA' },
  { name: 'servicesagreement', label: 'Services Agreement' },
  { name: 'subcontract', label: 'Subcontract' },
  { name: 'DFAR', label: 'DFAR' },
];

export const contractTypesMap = contractTypes.reduce(
  (acc, { name, label }) => ({ ...acc, [name]: label }),
  {}
);

export const defaultContractAttributesForExport = [
  '_id',
  'model',
  'name',
  'custodyLabel',
  'counterparty',
  'originalFilename',
  'slots',
  'storageState',
  'uploadUser',
  'userSlots',
  'groups',
  'custom_fields',
];

// ONLY OFFICE
export const ONLYOFFICE_BB_LOGO =
  'https://bbcedit-web-assets.s3.amazonaws.com/logos/BlackBoilerOneLinelogo.png';

export const ALIGNMENT_MODE_ENUMS_BY_INDEX = [
  'None',
  'Context before',
  'Context after',
  'Full context',
  'Tail',
];

export const DEFAULT_ACCEPTED_FILE_TYPES = ['.docx', '.doc', '.pdf'];

export const TRAIN_TYPES = {
  EMPTY: '',
  POINT: 'point',
  LIST_EDIT: 'list_edit',
  FSI: 'FSI',
  FPI: 'FPI',
  FSD: 'FSD',
  FPD: 'FPD',
};

export const EDIT_TYPES = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  REVISE: 'revise',
};

export const TEXT_TYPES = {
  SENTENCE: 'sentence',
  PARAGRAPH: 'paragraph',
};

export const RULE_STRENGTHS = [
  { name: 'Very Weak', value: 0 },
  { name: 'Weak', value: 1 },
  { name: 'Okay', value: 2 },
  { name: 'Strong', value: 3 },
  { name: 'Very Strong', value: 4 },
];

export const FIND_SIMILAR_DEFAULT_LIMIT = 5;

export const TRAIN_TYPES_EXTENDED = [
  { name: 'No Changes', value: '' },
  { name: 'Point Edit', value: 'point' },
  { name: 'Full Sentence Insert', value: 'FSI' },
  { name: 'Full Paragraph Insert', value: 'FPI' },
  { name: 'Full Sentence Delete', value: 'FSD' },
  { name: 'Full Paragraph Delete', value: 'FPD' },
  { name: 'List Edit', value: 'list_edit' },
];

export const TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY = {
  TRAINING: 'TRAINING',
  CLAUSE: 'CLAUSE',
};

export const FXI_POSITIONS = {
  BEFORE: 'before',
  AFTER: 'after',
  MIDDLE: 'middle',
  TAIL: 'tail',
};

export const TAIL_POSITIONS = {
  DOCUMENT: 'document',
  SECTION: 'section',
  PARAGRAPH: 'paragraph',
};

export const PARAMETER_DEFAULTS = {
  contextThresholdMin: 0.8,
  contextThresholdMax: 1,
  contextThresholdDefault: 0.85,
  presenceThresholdMin: 0.8,
  presenceThresholdMax: 1,
  presenceThresholdDefault: 0.85,
  contextWindowMin: 0,
  contextWindowMax: 100,
  contextWindowDefault: 50,
};

export const SLIDER_OPTIONS = [
  { key: 0, value: 'Low', visible: true },
  { key: 1, value: '', visible: false },
  { key: 2, value: '', visible: false },
  { key: 3, value: '', visible: false },
  { key: 4, value: '', visible: false },
  { key: 5, value: '', visible: false },
  { key: 6, value: '', visible: false },
  { key: 7, value: '', visible: false },
  { key: 8, value: '', visible: false },
  { key: 9, value: '', visible: false },
  { key: 10, value: 'High', visible: true },
];

export const RULE_STRENGTHS_MAP = Object.assign(
  {},
  ...RULE_STRENGTHS.map((rs) => ({ [rs.value]: rs.name }))
);

export const TRAIN_TYPES_MAP = Object.assign(
  {},
  ...TRAIN_TYPES_EXTENDED.map((tt) => ({ [tt.value]: tt.name }))
);

export const MAX_RULE_STRENGTH = 4;

export const US_STATE_NAMES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const CANADIAN_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Ontario',
  'Price Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];
